/**
 * AddGoodsmarkModal
 */

import React, { useContext, useState } from 'react';
import { LocalizationContext } from 'context/localization.context';
import Modal from 'components/ui/Modal';
import Button from '../Button';
import Icon from '../Icon';

interface Props {
	/** Toggle modal callback */
	onToggle: any;

	handleAddGoodsmark: any;

	goodsMarking?: string;

	warningMessage?: string;

	maxLength?: number;
}
const AddGoodsmarkModal = ({
	onToggle,
	handleAddGoodsmark,
	goodsMarking: defaultGoodMarking,
	warningMessage,
	maxLength = 21,
}: Props): JSX.Element => {
	const { t }: any = useContext(LocalizationContext);
	const [goodsmarking, setGoodsmarking] = useState(defaultGoodMarking || '');
	return (
		<Modal
			toggleModal={onToggle}
			heading={t('shared/goodsmark/editgoodsmark')}
			title="Add notes modal"
			width="small"
		>
			<div className="mb-5">
				<label className="block" htmlFor="goodsmarking">
					{t('shared/articlerow/goodsmarking')}
				</label>
				<textarea
					className="w-full h-25 p-2 border border-greyDark rounded-lg"
					id="goodsmarking"
					value={goodsmarking}
					maxLength={maxLength}
					onChange={(e) => setGoodsmarking(e.target.value)}
				></textarea>
			</div>

			{warningMessage ? (
				<div className="bg-grey px-4 py-3 flex items-baseline">
					<span>
						<Icon icon="alertTriangle" color="#0075BF" size={1.25} />
					</span>
					<span className="text-p ml-2 text-black">{warningMessage}</span>
				</div>
			) : (
				<></>
			)}

			<div className="flex justify-end">
				<Button
					className="block m-0"
					type="button"
					buttonColor="blue"
					disabled={!goodsmarking}
					onClick={() => handleAddGoodsmark(goodsmarking)}
				>
					{t('shared/articlerow/savenote')}
				</Button>
			</div>
		</Modal>
	);
};

export default AddGoodsmarkModal;
