/**
 * AutoComplete
 * @module components/AutoComplete
 */

import { useState, useEffect, useContext } from 'react';
import { useCombobox } from 'downshift';
import PropTypes from 'prop-types';
import { LocalizationContext } from 'context/localization.context';
import clsx from 'clsx';
import Icon from '../Icon';
import Loader from '../Loader';

const AutoComplete = ({
	query,
	handleChange,
	handleSearch,
	items,
	itemToString,
	autoCompleteLoading,
	labelText,
	placeholder,
	className,
	onBlur,
	onFocus,
	id,
	isQuickSearch,
	quickSearchIsEmpty,
	setRef,
}) => {
	const [value, setValue] = useState(query);
	const [inputValues, setInputValues] = useState(items);

	const { t } = useContext(LocalizationContext);

	useEffect(() => {
		setValue(query);
	}, [query]);

	const {
		isOpen,
		selectItem,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps,
		setInputValue,
		closeMenu,
		openMenu,
	} = useCombobox({
		items: inputValues,
		itemToString,
		// Dont use query here to prevent cursor move bug in useCombobox
		// https://github.com/downshift-js/downshift/issues/1108
		inputValue: value,
		onInputValueChange: ({ inputValue, selectedItem }) => {
			if (isQuickSearch) {
				handleChange && handleChange(inputValue);
			} else {
				handleChange && handleChange(inputValue, selectedItem);
			}
			if (inputValue.length > 0 && items && items.length > 0) {
				setInputValues(
					items.filter((item) =>
						item.name.toLowerCase().startsWith(inputValue.toLowerCase())
					)
				);
			}
		},
		onSelectedItemChange: ({ inputValue, selectedItem }) => {
			if (isQuickSearch) {
				handleSearch && handleSearch(inputValue, selectedItem);
				handleChange && handleChange(inputValue, selectedItem);
			} else {
				handleSearch && handleSearch(selectedItem.value);
				handleChange && handleChange(selectedItem.value);
			}
		},
	});

	const handleInputKeyDown = (e) => {
		if (e.keyCode === 13 && handleSearch) {
			handleSearch && handleSearch(query);
			closeMenu();
		}

		if (
			(e.keyCode === 13 || e.keyCode === 9) &&
			isQuickSearch &&
			inputValues &&
			inputValues.length === 1 &&
			highlightedIndex < 0
		) {
			selectItem(inputValues[0]);
			closeMenu();
		}
	};

	const handleBlur = (e) => {
		if (inputValues && inputValues.length > 0 && highlightedIndex >= 0) {
			const index = highlightedIndex > -1 ? highlightedIndex : 0;
			selectItem(inputValues[index]);
			onBlur && onBlur(inputValues[index]);
		}
		closeMenu();
		return true;
	};

	const handleOnFocus = () => {
		onFocus && onFocus();
		if (query.length > 0 && !isOpen) {
			openMenu();
		}
	};

	useEffect(() => {
		setInputValues(items);
		//eslint-disable-next-line
	}, [items]);

	useEffect(() => {
		setInputValue(query);
		//eslint-disable-next-line
	}, [query]);

	return (
		<div className={clsx(className, 'relative max-w-full autocomplete')}>
			<label {...getLabelProps()}>
				<span className="sr-only">
					{labelText || t('searchpage/searchfieldlabel')}
				</span>
			</label>
			<div aria-label={'toggle menu'} className="relative">
				{!isQuickSearch && (
					<Icon
						icon="search"
						className={clsx(
							'text-blue absolute top-0 left-0 mt-2.5 ml-2.5 w-4 h-4 md:w-5 md:h-5'
						)}
					/>
				)}
				<input
					placeholder={placeholder || t('searchpage/searchfieldplaceholder')}
					{...getInputProps({
						ref: setRef,
						onKeyDown: (event) => {
							handleInputKeyDown(event);
						},
						onChange: (e) => {
							setValue(e.target.value);
						},
					})}
					id={id}
					onFocus={handleOnFocus}
					onBlur={handleBlur}
					className={clsx(
						isQuickSearch
							? ' h-11 pt-2.5 bg-transparent rounded-tr-none rounded-br-none rounded-bl-none md:rounded-bl pr-0 text-sm md:text-p'
							: 'text-p pr-12',
						quickSearchIsEmpty ? 'pl-4' : 'pl-10',
						'pt-2 pb-3 w-full md:pr-6 h-10 rounded border border-greyDark'
					)}
				/>
			</div>
			<ul
				className={clsx(
					isOpen && items.length > 0 ? 'visible py-0 px-2' : 'invisible p-0',
					'absolute mt-0 bg-white w-full max-w-full md:max-w-none z-300'
				)}
				{...getMenuProps()}
			>
				{autoCompleteLoading && <Loader size="small" className="my-2 mx-0" />}
				{!autoCompleteLoading &&
					isOpen &&
					items.map((item, index) => (
						<li
							className={clsx(
								highlightedIndex === index && 'bg-grey',
								'p-2 text-p cursor-pointer'
							)}
							key={`${item}${index}`}
							{...getItemProps({
								item,
								index,
							})}
						>
							<span className="pointer-events-none">{itemToString(item)}</span>
						</li>
					))}
			</ul>
		</div>
	);
};

AutoComplete.propTypes = {
	query: PropTypes.string,
	handleChange: PropTypes.func,
	handleSearch: PropTypes.func,
	items: PropTypes.any,
	itemToString: PropTypes.any,
	autoCompleteLoading: PropTypes.any,
	labelText: PropTypes.any,
	placeholder: PropTypes.any,
	className: PropTypes.any,
	onBlur: PropTypes.any,
	onFocus: PropTypes.any,
	id: PropTypes.any,
	isQuickSearch: PropTypes.any,
	quickSearchIsEmpty: PropTypes.any,
};

export default AutoComplete;
