/**
 * QuickAddToCart
 * @module components/QuickAddToCart
 */

import { quickAddDetail, quickAddSearch } from 'api/quickadd';
import clsx from 'clsx';
import { Int32MaxValue } from 'common/constants';
import { GenericWarning, ServerError } from 'common/helpers';
import ArticleDetailConfiguration from 'components/common/ArticleDetailConfiguration';
import { LocalizationContext } from 'context/localization.context';
import { useDebounce, useToggle } from 'hooks';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { cancelPricingRequest } from 'store/api/cartApi';
import { AddToCart, selectCartObject } from 'store/modules/cart';
import { confReset, fetchConfArticle } from 'store/modules/configurator';
import { DictionaryModel } from 'types/common';
import AutoComplete from '../AutoComplete';
import Icon from '../Icon';
import SearchResultSummary from '../SearchResultSummary';
import { set } from 'date-fns';

interface Props {
	/** Pass optional className */
	className?: string;

	/** Api urls */
	api: DictionaryModel;

	sortSelection: string;

	fetchCartObject: any;

	activeMarket?: string;
}

interface ArticleProps {
	modelType: 'Article' | 'ConfArticle';
	link: string;
	name: string;
	itemName: string;
	itemCode: string;
	originalCode: string;
	stockQuantity: string;
	packingQuantity: string;
	unit: string;
}

const QuickAddToCart = ({
	className,
	api,
	sortSelection,
	fetchCartObject,
	activeMarket,
}: Props) => {
	const { withPrice } = useSelector(selectCartObject);
	const { t }: any = useContext(LocalizationContext);
	const dispatch = useDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const [query, setQuery] = useState('');
	const debouncedQuery = useDebounce(query, 500);
	const [quantity, setQuantity] = useState(1);
	const [selectedArticle, setSelectedArticle] = useState<ArticleProps | null>(
		null
	);
	const [confModalOpen, setConfModalOpen] = useState(false);

	const [selectionExpanded, toggleSelection, SelectionRef] = useToggle(
		false,
		false
	);
	const [articleItems, setArticleItems] = useState([]);
	const [suggestions, setSuggestions] = useState([]);

	const getSuggestions = async () => {
		try {
			const data = await quickAddSearch(
				debouncedQuery,
				api.quickAddSearchUrl,
				withPrice
			);
			if (data) {
				setArticleItems(data.items);
				setSuggestions(data.suggestions);
				!withPrice && fetchCartObject();
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (query !== '') {
			getSuggestions();
		} else {
			setArticleItems([]);
		}
		//eslint-disable-next-line
	}, [debouncedQuery]);

	const handleQueryOnFocus = () => {
		setQuery('');
		setQuantity(1);
		setSelectedArticle(null);
		toggleSelection(true);
	};

	const handleItem = async (item: ArticleProps) => {
		try {
			const data = await quickAddDetail(item.link, withPrice);

			if (data) {
				setSelectedArticle(data);
				setQuery(data.itemCode);
				(document.getElementById('qatc-quantity') as HTMLInputElement).select();

				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'add_to_cart',
						domain: activeMarket,
						ecommerce: {
							items: [
								{
									item_name: item.itemName,
									item_id: item.itemCode,
									item_variant: item.name,
									originalCode: item.originalCode,
									quantity: (document.getElementById(
										'qatc-quantity'
									) as HTMLInputElement).value,
								},
							],
						},
					});
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleQueryOnBlur = (item: ArticleProps) => {
		if (query !== '' && item) {
			toggleSelection(true);
			setQuery(item.itemCode);
		}
	};

	const handleAddToCart = async () => {
		try {
			if (selectedArticle && selectedArticle.itemCode) {
				const articleList = [
					{
						itemCode: selectedArticle.itemCode,
						originalCode: selectedArticle.originalCode,
						quantity: quantity,
					},
				];
				await dispatch(AddToCart('POST', articleList, sortSelection));

				toast(t('shared/toast/cartaddsuccess'), {
					type: toast.TYPE.SUCCESS,
				});
			}
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartupdateerror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	const handleQuantityChange = (e: any) => {
		let value = e.currentTarget.value;
		if (parseInt(value) > Int32MaxValue) {
			value = Int32MaxValue;
		}

		if (
			parseInt(value) > -1 ||
			value === undefined ||
			value === null ||
			value === ''
		) {
			setQuantity(value);
		}
	};

	const handleQuantityKeyDown = (e: any) => {
		if (e.keyCode === 13) {
			inputRef && inputRef.current?.focus();
			handleAddToCart();
			handleQueryOnFocus();
		}
	};

	const getConfArticle = async (url: string) => {
		try {
			setConfModalOpen(true);
			if (!withPrice) {
				cancelPricingRequest();
			}
			await dispatch(fetchConfArticle(url));
		} catch (err: any) {
			console.log(err);
		}
	};

	const handleQueryChange = (value: string, item: ArticleProps) => {
		if (
			item &&
			item.itemCode !== (selectedArticle && selectedArticle.itemCode)
		) {
			if (item.modelType === 'ConfArticle') {
				getConfArticle(item.link);
				handleQueryOnFocus();
			} else {
				handleItem(item);
				setQuery(item.itemCode);
			}
		} else {
			setQuery(value);
		}
	};

	const handleSuggestion = (newQuery: string) => {
		if (query !== newQuery) {
			inputRef && inputRef.current?.focus();
			setQuery(newQuery);
		}
	};

	const toggleConfModal = () => {
		setConfModalOpen(false);
		dispatch(confReset());
		setQuery('');
		if (!withPrice) {
			fetchCartObject();
		}
	};
	return (
		<>
			<div
				className={clsx(
					className,
					'relative flex flex-wrap md:flex-no-wrap max-w-full w-full'
				)}
			>
				{selectedArticle && (
					<button
						className="absolute w-10 h-11 z-10 bg-transparent"
						onClick={handleQueryOnFocus}
						aria-hidden={true}
					>
						<Icon icon="close" size={2} color="#949494" className="m-2" />
					</button>
				)}
				<AutoComplete
					className={clsx(
						!selectedArticle ? 'md:w-9/12 lg:w-full' : ' md:w-2/12',
						'inline-block w-7/12'
					)}
					id="quickaddinput"
					setRef={inputRef}
					query={query}
					placeholder={t('quickadd/quickorderplaceholder')}
					labelText={t('quickadd/quickorderplaceholder')}
					handleChange={handleQueryChange}
					onFocus={handleQueryOnFocus}
					onBlur={handleQueryOnBlur}
					items={(query && articleItems) || []}
					itemToString={(item: ArticleProps) =>
						item ? `${item.itemCode} ${item.itemName} ${item.name}` : ''
					}
					autoCompleteLoading={false}
					isQuickSearch
					quickSearchIsEmpty={!selectedArticle}
				/>
				<div
					ref={SelectionRef}
					className={clsx(
						selectedArticle && selectionExpanded
							? 'visible static'
							: 'invisible absolute',
						'w-full md:w-8/12 flex md:flex-no-wrap bg-grey whitespace-no-wrap md:h-11 order-4 md:order-none'
					)}
				>
					<p className="text-p w-full md:w-4/12 border border-greyDark py-3 md:py-2.5 px-2 overflow-hidden overflow-ellipsis">
						{selectedArticle && selectedArticle.itemName}
					</p>
					<div className="text-p w-full md:w-8/12 border border-greyDark py-1 px-2 overflow-hidden overflow-ellipsis">
						{selectedArticle && selectedArticle.name}
						<div className="-mt-2">
							{selectedArticle?.stockQuantity && (
								<span className="text-xsm font-extralight mr-2">
									{`${t('shared/articlerow/stocklevel')}: ${
										selectedArticle.stockQuantity
									}`}
								</span>
							)}
							<span className="text-xsm font-extralight">
								{`${t('shared/articlerow/packagesize')}: ${
									selectedArticle && selectedArticle.packingQuantity
								}`}
							</span>
						</div>
					</div>
				</div>
				<label className="sr-only" htmlFor={'qatc-quantity'}>
					{t('quickadd/quantitylabel')}
				</label>
				<input
					id={'qatc-quantity'}
					name={'qatc-quantity'}
					type="number"
					max={Int32MaxValue}
					min={0}
					value={quantity}
					onChange={handleQuantityChange}
					onKeyDown={handleQuantityKeyDown}
					autoComplete="off"
					className="text-p w-3/12 md:w-20 py-2 px-0 top-0 right-0 inline-block text-center h-11 rounded-none border border-greyDark"
				/>
				<p className="inline-block w-2/12 h-11 md:w-auto text-sm text-center lg:text-p bg-grey m-0 pt-3 pb-2 px-2">
					{selectedArticle && selectedArticle?.unit}
				</p>
				<button
					type="button"
					disabled={isNaN(quantity) || quantity < 1}
					onClick={() => {
						inputRef && inputRef.current?.focus();
						handleAddToCart();
						handleQueryOnFocus();
					}}
					className="font-standard button text-white bg-blue border-blue border-2 border-solid font-bold align-middle text-center cursor-pointer inline-block uppercase py-2 px-3 rounded-lg no-underline text-p smMax:py-0.5 smMax:px-1.5 smMax:text-sm disabled:text-greyDarker disabled:bg-grey disabled:border-grey disabled:border-solid disabled:border-2 w-full md:w-2/12 mb-0 mt-0 sm-text md:p-text rounded-tr-none rounded-tl-none md:rounded-tr md:rounded-bl-none order-5 md:order-none hover:bg-blue-hover"
				>
					{t('quickadd/addtocart')}
				</button>
			</div>
			{query && articleItems?.length === 0 && (
				<SearchResultSummary
					query={query}
					pagination={{}}
					suggestions={suggestions}
					handleSuggestion={handleSuggestion}
					className="mt-4"
				/>
			)}
			{confModalOpen && (
				<ArticleDetailConfiguration closeModal={toggleConfModal} />
			)}
		</>
	);
};

export default QuickAddToCart;
